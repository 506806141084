import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import withTooltip from "../../../hocs/withTooltip";

const ProcessIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon viewBox="0 0 116.56 104.4" {...props} ref={ref}>
        <path
            d="M-98.45,548.54c1.55,0,2.9,0,4.26,0A8.22,8.22,0,0,1-86,556c.15,1.51.08,3,.09,4.55,0,2.88,0,5.75,0,8.73h-66.62v-1.23q0-5.46,0-10.91a8.31,8.31,0,0,1,8.61-8.64h3.89c0-1.48,0-2.86,0-4.24a20.88,20.88,0,0,1,14.22-19.66,20.83,20.83,0,0,1,27.4,19.69C-98.43,545.65-98.45,547-98.45,548.54Zm-16.88-4.21a4.12,4.12,0,0,0-4.1-4.12,4.15,4.15,0,0,0-4.17,4.19,4.17,4.17,0,0,0,4.12,4.12A4.12,4.12,0,0,0-115.33,544.33Z"
            transform="translate(202.52 -477.38)"
        />
        <path
            d="M-103.9,524.63a25.11,25.11,0,0,0-38.64,10.45c-.25-.44-.47-.8-.67-1.17-6.38-12-12.6-24.18-19.21-36.1-5.3-9.58,1.44-18.11,9.12-20A11.74,11.74,0,0,1-141,481.57q17.32,19.43,34.55,38.95a47.83,47.83,0,0,1,2.84,3.93Z"
            transform="translate(202.52 -477.38)"
        />
        <path
            d="M-168.49,490.49c-.2,4.17,1.52,7.58,3.37,11,2.45,4.48,4.79,9,7.22,13.54a1.13,1.13,0,0,1-.22,1.62c-4.75,4.7-9.43,9.46-14.2,14.14a12.91,12.91,0,0,1-18.08,0,12.81,12.81,0,0,1-.46-18.06c7.21-7.43,14.62-14.67,21.94-22A4.2,4.2,0,0,1-168.49,490.49Z"
            transform="translate(202.52 -477.38)"
        />
        <path d="M-152.51,581.77v-8.21h66.44v8.21Z" transform="translate(202.52 -477.38)" />
        <path
            d="M-178.18,538.35c.53,2.54,1.07,4.95-1.17,6.67a49,49,0,0,1,1.58,5.57,11.31,11.31,0,0,1-2.06,8.41,4.19,4.19,0,0,1-5.79,1.32,4.19,4.19,0,0,1-1.15-5.91c1.63-2.65.5-5.72-2.35-6.34a3.8,3.8,0,0,0-4,1.45,3.85,3.85,0,0,0-.43,4.29c.14.28.34.54.5.82a4.16,4.16,0,0,1-1.21,5.62,4.11,4.11,0,0,1-5.74-1.08,12.13,12.13,0,0,1-1.06-12.62c.18-.39.39-.77.64-1.27-2.36-1.52-2.26-3.78-1.75-6.1a12.29,12.29,0,0,1,6.4-8.45C-191.48,536.79-185.65,539.32-178.18,538.35Z"
            transform="translate(202.52 -477.38)"
        />
    </SvgIcon>
));
export default withTooltip(ProcessIcon);

import { CurrencyDto } from "api-shared";
import React from "react";
import useCurrency from "../hooks/useCurrency";

interface ICurrencyProps {
    value: number | null | undefined;
    nullValue?: React.ReactNode;
    currency?: CurrencyDto;
}

const Currency = ({ value, nullValue = "--", currency }: ICurrencyProps) => {
    const { formatCurrency } = useCurrency({ currency });

    if (value == null) {
        return <>{nullValue}</>;
    }

    return <>{formatCurrency(value)}</>;
};

export default Currency;

import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import withTooltip from "../../../hocs/withTooltip";

const WasteIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon viewBox="0 0 105.21 119.44" {...props} ref={ref}>
        <path
            d="M-311.46,501.45H-217c0,.46.07.88.07,1.3q0,38.64,0,77.28a7.59,7.59,0,0,1-3.27,6.35,11.52,11.52,0,0,1-7.18,2.3q-36.78,0-73.56,0a11.69,11.69,0,0,1-7.79-2.64,7.52,7.52,0,0,1-2.86-6.06q.06-38.64.05-77.28C-311.52,502.29-311.48,501.9-311.46,501.45Zm36.73,41.23h0q0,13.62,0,27.23a8.79,8.79,0,0,0,3.86,7.46,11.56,11.56,0,0,0,13.56-.18,8.53,8.53,0,0,0,3.61-7q0-27.45,0-54.92a8.4,8.4,0,0,0-2.88-6.37,10.94,10.94,0,0,0-7.31-2.89c-5.58-.17-11.1,3.74-10.9,10.4C-274.54,525.17-274.73,533.93-274.73,542.68Zm-21-.06h0q0,13.92,0,27.83a12.34,12.34,0,0,0,1.67,6.55c2,3.26,5.09,3.28,7.1,0a12.16,12.16,0,0,0,1.7-6.53c0-18.48.05-37,0-55.43a16.91,16.91,0,0,0-1-5.25c-.65-1.94-1.82-3.76-4.16-3.77s-3.58,1.78-4.19,3.72a21.85,21.85,0,0,0-1,6.09C-295.81,524.78-295.75,533.7-295.75,542.62Zm63,.16h0q0-13.62,0-27.23a13.56,13.56,0,0,0-1.32-6.4c-.83-1.61-1.9-3.1-3.93-3.09s-3.09,1.48-3.92,3.1a14.27,14.27,0,0,0-1.33,6.76q0,27,0,54a19.13,19.13,0,0,0,.12,2.39,10.18,10.18,0,0,0,2.44,5.88,3.37,3.37,0,0,0,5.34,0,5.47,5.47,0,0,0,1.36-2.09,26.17,26.17,0,0,0,1.17-6.06C-232.64,560.94-232.71,551.86-232.71,542.78Zm5.44.08h0c0,9,0,18,0,27a44.52,44.52,0,0,0,.57,6.2,8.4,8.4,0,0,0,1,2.78c.5.88,1.22.87,1.79,0a4.6,4.6,0,0,0,.79-1.73,39.49,39.49,0,0,0,.84-6.27q.1-28.08,0-56.15a37.44,37.44,0,0,0-.94-6.62c-.16-.86-.53-2.08-1.59-1.93-.6.09-1.36,1.16-1.5,1.9a40.48,40.48,0,0,0-1,7.33C-227.33,524.54-227.27,533.7-227.27,542.86Zm-79,0h0v15.72c0,4.24-.14,8.48.06,12.71a40.57,40.57,0,0,0,1.28,7,1.75,1.75,0,0,0,1.24,1.06c.34,0,.87-.59,1.14-1a5.23,5.23,0,0,0,.66-1.79,39.39,39.39,0,0,0,.68-5.7q.09-28.08,0-56.15a32.19,32.19,0,0,0-1-6.73c-.16-.72-1-1.29-1.54-1.93-.5.63-1.32,1.19-1.45,1.89a41.87,41.87,0,0,0-1,7.44C-306.32,524.5-306.25,533.66-306.25,542.82Z"
            transform="translate(316.82 -469.25)"
        />
        <path
            d="M-211.62,496.77H-316.78v-5.08c0-.88.07-1.77,0-2.64-.6-7.12,5.5-10.68,10.63-10.64,6.44,0,12.88,0,19.31,0h1.6v-3.76c0-3.34,2-5.38,5.36-5.4,3.88,0,7.76,0,11.64,0l19.08,0c4.19,0,6,1.81,6,5.94v3.25h1.52l19.31,0a11.17,11.17,0,0,1,7.25,2.41,8.58,8.58,0,0,1,3.44,7.06C-211.63,490.86-211.62,493.78-211.62,496.77Zm-63-18.43h20.87V474h-20.87Z"
            transform="translate(316.82 -469.25)"
        />
    </SvgIcon>
));
export default withTooltip(WasteIcon);

import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import withTooltip from "../../../hocs/withTooltip";

const ContractIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon viewBox="0 0 116.09 112.13" {...props} ref={ref}>
        <path
            d="M-101.22,579.67v50.59h-96V518.13H-132a15.5,15.5,0,0,1,11.38,4.55q7.53,7.47,15,15c.47.48.88,1,1.35,1.56-1.44,1.16-2.79,2.29-4.19,3.34a2.08,2.08,0,0,1-1.13.25,9.86,9.86,0,0,0-7,3c-1.24,1.25-2.47,2.51-3.73,3.74a2,2,0,0,1-1.08.62c-3.88,0-7.75,0-11.75,0,0-.51-.06-.93-.06-1.35V530.62c0-2.87-1.58-4.45-4.47-4.45h-50.39c-.35,0-.71,0-1.14.05v96h80V620.8c0-10.68,0-21.36,0-32a3,3,0,0,1,.76-1.85C-106.12,584.51-103.74,582.19-101.22,579.67Zm-12.59-37.59-11.3-11.3v11.3Z"
            transform="translate(197.21 -518.13)"
        />
        <path
            d="M-113.28,561.93l3.89-3.45-.16-.28c-1.66,0-3.32,0-5,0a1.58,1.58,0,0,0-.88.53c-3.68,3.67-7.36,7.34-11,11.06a1.85,1.85,0,0,0-.4,1.42,4.56,4.56,0,0,1-2.46,5.21,4.46,4.46,0,0,1-5.48-1.39,4.44,4.44,0,0,1,.45-5.82q10.3-10.4,20.66-20.74a5.78,5.78,0,0,1,4.72-1.64,3.6,3.6,0,0,0,2.8-1,49.73,49.73,0,0,1,11-7.51,13.71,13.71,0,0,1,3.73-1.22A5.2,5.2,0,0,1-87,538.78c1.41,1.37,2.81,2.74,4.16,4.16a5.73,5.73,0,0,1,1.19,6.45,34.42,34.42,0,0,1-6.13,10c-4.31,4.93-8.85,9.67-13.3,14.48a.91.91,0,0,1-.22.14Z"
            transform="translate(197.21 -518.13)"
        />
        <path
            d="M-138,577.4a8.53,8.53,0,0,0,7.68,3.41,8.49,8.49,0,0,0,7.68-8.16A2.89,2.89,0,0,1-122,571c1.9-2,3.88-3.91,5.82-5.84l11.79,11.9a.59.59,0,0,1-.12.2c-7.22,7-14.33,14.05-21.72,20.83a51.64,51.64,0,0,1-15.66,9.84,4.45,4.45,0,0,1-5.52-1.17c-1-1.15-2.16-2.25-3.3-3.31a4.35,4.35,0,0,1-1.06-5.33,68.47,68.47,0,0,1,10.88-17.43C-140,579.62-139,578.58-138,577.4Z"
            transform="translate(197.21 -518.13)"
        />
        <path
            d="M-177.9,603.24c.1-.38.2-.76.29-1.13a1.92,1.92,0,0,1,2-1.62,1.84,1.84,0,0,1,2,1.61c.28,1.45.47,2.92.7,4.38,0,.19.1.37.15.58l1.89-.22c.24-1.87.47-3.76.71-5.66a2,2,0,0,1,1.85-1.88,1.94,1.94,0,0,1,2.13,1.82q.42,2.85.7,5.71c.07.74.31,1.14,1.09,1.29,1.09.22,2.15.57,3.14.84l.46,4.18c-.94-.22-1.87-.4-2.78-.68-.69-.21-1.22-.24-1.54.54a1.24,1.24,0,0,1-.56.61c-.46.19-1.08.51-1.44.36-1.39-.61-3.38.94-4.31-1.22a5.14,5.14,0,0,0-.61-.85c-1,.77-2,1.74-3.51.7-.2,1-.38,1.83-.56,2.68-.28,1.37-1,2.08-2.09,2.06s-1.76-.71-2-2.11c0-.2-.09-.39-.16-.7-.2.1-.36.16-.5.25a1.68,1.68,0,0,1-2.21.09,3,3,0,0,1-.94-2c.19-3.51.55-7,.85-10.51.12-1.36.24-2.71.37-4.06a2.06,2.06,0,0,1,1.9-2.1,2.06,2.06,0,0,1,2.15,2c.27,1.69.52,3.38.78,5.07Z"
            transform="translate(197.21 -518.13)"
        />
        <path
            d="M-154.19,605.92l4.32,4.23c-.81.78-1.63,1.65-2.54,2.41a2.94,2.94,0,0,1-3.94-.19,2.91,2.91,0,0,1-.18-4C-155.78,607.47-154.93,606.68-154.19,605.92Z"
            transform="translate(197.21 -518.13)"
        />
    </SvgIcon>
));
export default withTooltip(ContractIcon);

import { Paper, styled } from "@mui/material";
import BaseTable, { IBaseTableProps } from "./BaseTable";

const FullHeightContainer = styled(Paper)({
    height: "100%",
});

interface IPaperTableProps<D extends object = Record<string, unknown>> extends IBaseTableProps<D> {
    square?: boolean;
}

const PaperTable = <D extends object = Record<string, unknown>>({ square, ...tableProps }: IPaperTableProps<D>) => {
    const Container = tableProps.fullHeight ? FullHeightContainer : Paper;
    return (
        <Container>
            <BaseTable {...tableProps} />
        </Container>
    );
};

export default PaperTable;

import { FileDto } from "api-shared";
import { TFunction } from "i18next";
import { Fragment } from "react";
import { useAllUsers } from "../../domain/users";
import { formatSize } from "../../lib/formatters";
import DateTz from "../DateTz";
import UserName from "../user/UserName";
import DeleteDialog, { IDeleteDialogProps } from "./DeleteDialog";

interface IDeleteFileDialogProps extends IDeleteDialogProps {
    onDelete: () => void;
    file?: FileDto;
    translate: TFunction;
}

const DeleteFileDialog = ({ onDelete, file, translate, ...other }: IDeleteFileDialogProps) => {
    const users = useAllUsers();
    return (
        <DeleteDialog item="file" onDelete={onDelete} translate={translate} {...other}>
            {file != null ? (
                <Fragment>
                    {`${file.filename} (${formatSize(file.size ?? 0)})`}
                    <br />
                    <UserName userId={file.createdById} users={users} translate={translate} />
                    {", "}
                    <DateTz date={file.createdAt} />
                </Fragment>
            ) : null}
            <br />
            {translate("remove_file_description")}
        </DeleteDialog>
    );
};

export default DeleteFileDialog;

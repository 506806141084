import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import withTooltip from "../../../hocs/withTooltip";

const MakeOrBuyIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon viewBox="0 0 120.47 115.03" {...props} ref={ref}>
        <path
            d="M-268.22,502.21v44.16H-312.6v-1.44q0-23,.06-45.95a2.63,2.63,0,0,1,.62-1.68q21.66-21.71,43.37-43.34c.08-.08.18-.15.36-.3.28.26.57.5.84.77q21.36,21.4,42.7,42.83a2.64,2.64,0,0,1,.72,1.64q0,23.22,0,46.44c0,.35,0,.69-.07,1.1h-17.63V502.21Zm-8.95,0h-26.46v17.59h26.46Z"
            transform="translate(328.45 -431.41)"
        />
        <path
            d="M-268.26,447.31c-2.45,2.49-4.82,4.92-7.22,7.32l-42.1,42.05c-2.7,2.69-5,2.7-7.64,0-.59-.6-1.19-1.18-1.78-1.79a4.44,4.44,0,0,1,.05-6.61c3.88-3.92,7.8-7.81,11.7-11.7l43.3-43.23c2.65-2.64,5-2.6,7.64.07q27.18,27.27,54.39,54.53c2.59,2.61,2.59,4.93,0,7.54l-1.7,1.7a4.46,4.46,0,0,1-6.78,0q-6.21-6.19-12.37-12.4L-267.56,448Z"
            transform="translate(328.45 -431.41)"
        />
    </SvgIcon>
));
export default withTooltip(MakeOrBuyIcon);

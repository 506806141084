import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import withTooltip from "../../../hocs/withTooltip";

const QuantityIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon viewBox="0 0 117.26 115.87" {...props} ref={ref}>
        <path
            d="M-190.31,444.12h31.68c6.19,0,10.21,5.11,8.75,11.15q-5.07,21-10.18,42c-1.26,5.16-5.48,7.78-10.7,6.68l-47.17-10c-4.45-.94-6.72-3.25-7.53-7.74q-2.82-15.7-5.6-31.41c-1.05-6,2.91-10.63,9-10.63q15.9,0,31.79,0Zm13.09,38.56V470H-190v12.69Zm4.44-29.69v12.47c3.72,0,7.35,0,11,0,.27,0,.68-.47.77-.79.46-1.61.82-3.25,1.22-4.88.54-2.23,1.08-4.47,1.64-6.77Zm-21.62,17h-12.67v12.67h12.67Zm4.45-17v12.4h12.71V453Zm-17.15,0v12.39h12.65V453Zm-15.52,0c.72,4.05,1.41,8,2.14,12a.76.76,0,0,0,.55.48c2.77,0,5.55,0,8.37,0V453Zm60.32,17h-10.45v12.7h7.36Zm-57.31,0c.73,4.1,1.43,8.07,2.17,12,.05.27.38.67.59.68,1.75.06,3.5,0,5.31,0V470Zm42.38,17.27H-190a20.53,20.53,0,0,1,0,2.36c-.12,1,.31,1.29,1.21,1.46,3,.58,6,1.25,8.92,1.88l2.68.53Zm4.45-.06v7.26l4.31.91,2-8.17Zm-33.22,0v.23l11.57,2.44v-2.67Z"
            transform="translate(231.23 -435.55)"
        />
        <path
            d="M-147.14,508.46c-.6-1.45-1.12-2.7-1.63-3.95-1.41-3.4-2.78-6.81-4.23-10.19a7.64,7.64,0,0,1-.4-5q5-19.83,9.88-39.68a6.46,6.46,0,0,1,3.32-4.33c5.57-3,11.14-6,16.72-8.92a6.38,6.38,0,0,1,7.46.9,6.42,6.42,0,0,1,1.52,7.27,6.56,6.56,0,0,1-3.09,3.23c-4.37,2.32-8.73,4.69-13.11,7a2.24,2.24,0,0,0-1.26,1.59c-2.75,11.14-5.53,22.27-8.27,33.41a3.39,3.39,0,0,0,.16,1.94q4.2,10,8.48,20c2.21,5.2-.72,9.63-6.37,9.64l-77.26,0c-3.81,0-6.48-2.26-6.89-5.78a6.39,6.39,0,0,1,5.27-6.94,15.56,15.56,0,0,1,2.16-.12l66.1-.05Z"
            transform="translate(231.23 -435.55)"
        />
        <path
            d="M-142.86,551.38a12.88,12.88,0,0,1-12.83-13,12.87,12.87,0,0,1,13-12.78,12.87,12.87,0,0,1,12.77,12.89A12.88,12.88,0,0,1-142.86,551.38Z"
            transform="translate(231.23 -435.55)"
        />
        <path
            d="M-211.43,551.42a12.85,12.85,0,0,1-12.87-12.9,12.88,12.88,0,0,1,13-12.84,12.9,12.9,0,0,1,12.81,12.86A12.87,12.87,0,0,1-211.43,551.42Z"
            transform="translate(231.23 -435.55)"
        />
    </SvgIcon>
));
export default withTooltip(QuantityIcon);

import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { SvgIconProps } from "@mui/material";
import React from "react";
import ContractIcon from "./ContractIcon";
import MakeOrBuyIcon from "./MakeOrBuyIcon";
import MarketIcon from "./MarketIcon";
import PriceIcon from "./PriceIcon";
import ProcessIcon from "./ProcessIcon";
import ProductIcon from "./ProductIcon";
import QuantityIcon from "./QuantityIcon";
import WasteIcon from "./WasteIcon";

const icons = {
    contract: ContractIcon,
    makeorbuy: MakeOrBuyIcon,
    market: MarketIcon,
    price: PriceIcon,
    process: ProcessIcon,
    product: ProductIcon,
    quantity: QuantityIcon,
    waste: WasteIcon,
    custom: CorporateFareIcon,
};

interface IValueLeverIconProps extends SvgIconProps {
    type: keyof typeof icons;
}

const ValueLeverIcon = React.forwardRef<SVGSVGElement, IValueLeverIconProps>(({ type, ...otherProps }, ref) => {
    const Icon = icons[type];
    return Icon != null ? <Icon {...otherProps} ref={ref} /> : null;
});

export default ValueLeverIcon;

import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import withTooltip from "../../../hocs/withTooltip";

const ProductIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon viewBox="0 0 86.01 120.57" {...props} ref={ref}>
        <path
            d="M-149.86,478.63a7.77,7.77,0,0,0,6.57-4.69c3.06-6.27,2.9-12.41-1.14-18.28-.09-.12-.17-.25-.25-.37,8.48-7.3,9.65-12.24,5.09-22,.6.27,1.07.45,1.51.68,9,4.61,14.55,12.05,17,21.68,3.26,12.79,1,24.55-7.79,34.66-2,2.29-4.63,4-7,5.9a1.76,1.76,0,0,0-.88,1.63c.06,2.11,0,4.23,0,6.49.46,0,.84.05,1.22.05,2.4,0,4.8,0,7.2,0a8.56,8.56,0,0,1,8.7,8.65c0,2.8,0,5.59,0,8.47h-86v-1.17c0-2.2,0-4.4,0-6.6,0-5.9,3.5-9.43,9.37-9.43l31.79,0h1.72V491.51h4.2c1.48,0,3,0,4.44,0a4.21,4.21,0,0,0,4.27-4.27c.05-2.39,0-4.79,0-7.19Z"
            transform="translate(205.65 -400.94)"
        />
        <path
            d="M-184.28,409.59c-.82,0-1.46,0-2.09,0a2.12,2.12,0,0,1-2.22-2.19c0-1.43,0-2.87,0-4.31a2,2,0,0,1,2.14-2.11q10.74,0,21.47,0a2.05,2.05,0,0,1,2.18,2.2c0,1.28,0,2.56,0,3.84,0,1.76-.79,2.55-2.56,2.58h-1.73c0,2,.14,3.9,0,5.79a4.77,4.77,0,0,0,2,4.75,5.2,5.2,0,0,1,2.37,5.54,3.91,3.91,0,0,0,0,1.08,10.81,10.81,0,0,1-2,7.3c-3.34,4.77-3.26,10.06-.54,15.21.44.85.45,1.36-.31,1.89a3.76,3.76,0,0,0-1.5,3.62c.11,3.31.06,6.64,0,9.95a4.19,4.19,0,0,1-.52,1.37s-.06.06-.09.09c-1.22,1.2-2.25,2.86-3.72,3.48s-3.39.05-5.09.24a6.46,6.46,0,0,1-6-2.36,5.94,5.94,0,0,1-1.9-4.7c.17-3,.08-6.08,0-9.11a3.26,3.26,0,0,0-.78-1.85c-1.08-1.23-2.73-2.17-3.31-3.57s-.19-3.3-.19-5c0-6.6,0-13.2,0-19.79a2.93,2.93,0,0,1,.72-1.76c1.1-1.26,2.78-2.23,3.36-3.67s.16-3.39.18-5.1Z"
            transform="translate(205.65 -400.94)"
        />
        <path d="M-154.23,478.73a6.6,6.6,0,0,1,.08.79c0,2.51,0,5,0,7.65h-43v-8.44Z" transform="translate(205.65 -400.94)" />
        <path
            d="M-152.06,431.23A10.75,10.75,0,0,1-141.28,442a10.77,10.77,0,0,1-10.82,10.82A10.78,10.78,0,0,1-162.82,442,10.75,10.75,0,0,1-152.06,431.23Z"
            transform="translate(205.65 -400.94)"
        />
    </SvgIcon>
));
export default withTooltip(ProductIcon);

import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import withTooltip from "../../../hocs/withTooltip";

const PriceIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon viewBox="0 0 116.14 66.37" {...props} ref={ref}>
        <path
            d="M-268.29,574.89h-52.92c-3.61,0-5.07-1.48-5.07-5.11q0-28.14,0-56.27c0-3.47,1.5-5,5-5l106.31,0c3.28,0,4.85,1.57,4.85,4.85q0,28.38,0,56.76c0,3.11-1.62,4.73-4.73,4.73h-53.4Zm37.36-62.14h-33.86c15.9,2.19,25.32,15.42,25.49,27.22.24,17.15-8.95,26.95-24.67,30.7h33a17,17,0,0,1,16.59-16.6V529.33a16.62,16.62,0,0,1-11.62-4.9A16.67,16.67,0,0,1-230.93,512.75ZM-322,529.3V554c10.11,1.13,15.61,6.71,16.66,16.73h33.55c-15.07-1.74-25.73-15.48-25.45-29.53a29.48,29.48,0,0,1,25.44-28.43h-33.64a16.71,16.71,0,0,1-4.82,11.57A16.66,16.66,0,0,1-322,529.3Zm30,18.64a29.25,29.25,0,0,0,3.26,0c1.32-.16,1.89.31,2.37,1.6,3.92,10.4,15.15,15.55,25.11,11.61,2.38-.94,2.38-.94,1.88-3.39-.31-1.53-.6-3.06-.91-4.59-.12-.61-.28-1.2-.43-1.85-6.79,4.57-15.11,2.93-17.13-3.36h1.33c3.64,0,7.28,0,10.92,0a1.33,1.33,0,0,0,1-.57c.35-1.09.53-2.23.81-3.56h-15.7l-.16-2.06H-267c3.89,0,3.89,0,4.42-3.87,0,0,0-.07-.05-.26H-279a11.83,11.83,0,0,1,6.15-7.24c4.07-1.93,7.92-1.24,11.61,1.45.57-2.62,1.08-5.12,1.66-7.6a1.07,1.07,0,0,0-.72-1.46,17.91,17.91,0,0,0-10.89-1.49c-8.38,1.59-13.55,6.76-15.95,14.8-.34,1.13-.66,1.78-1.95,1.57a1,1,0,0,0-.83.42c-.32,1.17-.53,2.37-.82,3.74h2.8v2c-.88,0-1.72,0-2.55,0a1,1,0,0,0-.77.49C-291.55,545.47-291.77,546.64-292,547.94Z"
            transform="translate(326.28 -508.54)"
        />
    </SvgIcon>
));
export default withTooltip(PriceIcon);
